var router = new VueRouter({
	mode: 'history', //to set it to html5mode, other value can be 'hash' for hashband method
	base: window.owConfig.apiBase, //this is needed in html5mode to calculate relative url
  /*scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },*/
	routes: [
		{
			name: 'of-main',
			path: '/',
			component(resolve) {
				owLazyRoute('of-main', resolve);
			}
		},
		{
			name: 'of-about',
			path: '/about',
			component(resolve) {
				owLazyRoute('of-about', resolve);
			}
		},
		{
			name: 'of-driverFund',
			path: '/drive-the-driver',
			component(resolve) {
				owLazyRoute('of-driverFund', resolve);
			}
		},
		// {
		// 	name: 'of-o2donate',
		// 	path: '/donate',
		// 	component(resolve) {
		// 		owLazyRoute('of-o2donate', resolve);
		// 	}
		// },
		{
			name: 'of-donate',
			path: '/sheild-success',
			component(resolve) {
				owLazyRoute('of-donate-success', resolve);
			}
		},
		{
			name: 'of-donate',
			path: '/sheild-failure',
			component(resolve) {
				owLazyRoute('of-donate-failure', resolve);
			}
		},
		{
			name: 'of-gallery',
			path: '/gallery',
			component(resolve) {
				owLazyRoute('of-gallery', resolve);
			}
		},
		{
			name: 'of-donate',
			path: '/donate-success',
			component(resolve) {
				owLazyRoute('of-donate-success', resolve);
			}
		},
		{
			name: 'of-donate',
			path: '/donate-failure',
			component(resolve) {
				owLazyRoute('of-donate-failure', resolve);
			}
		},

		// {
		// 	path: '/*',
		// 	redirect: {
		// 		name: 'owhn'
		// 	}
		// }
	]
});